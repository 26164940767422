import React from "react"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"

export default function CardStoriesHomePage() {

  /**
   * Utility for adding a common query param to the thumbnail images.
   * There's a bug with handling this in CosmicJS.
   * @param url
   * @returns {string}
   */
  const imgixUrl = (url) => {
    const q = "?w=228&h=143&fit=crop&crop=top"
    return url + q;
  }

  return (
    <StaticQuery
      query={graphql`
       {
        allCosmicjsStories(limit: 3, filter: {metadata: {story_display_on_homepage: {eq: true}}}, sort: {order: ASC, fields: metafields___object___modified_at}) {
          edges {
            node {
              id
              title
              slug
              metadata {
                story_job_title
                story_company
                story_quote
                story_personal_photo {
                  imgix_url
                }
              }
            }
          }
        }
      }
      `}
      render={data => (
        data.allCosmicjsStories.edges.map(({ node }, idx) => {
          return (
            <div className="component-card component-card-stories" key={idx}>
              <div className="component-card-image component-card-image-stories">
                <img src={imgixUrl(node.metadata.story_personal_photo.imgix_url)} alt="story" />
              </div>
              <div className="component-card-meta component-card-meta-stories">
                <div className="component-card-meta-header component-card-meta-header-stories">
                  <Link className="component-card-title component-card-title-stories" to="/coming-soon">{node.metadata.story_job_title}</Link>
                </div>
                <div className="component-card-company component-card-company-stories">{node.metadata.story_company}</div>
                <blockquote className="component-blockquote component-blockquote-stories">
                  <p>{node.metadata.story_quote}</p>
                  <cite>&mdash; {node.title}</cite>
                </blockquote>
              </div>
            </div>
          )
        })
      )}
    />
  )
}
