import React from "react"
import { Link } from "gatsby"
import {graphql} from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo"
import StreamStoriesHomePage from "../components/streams/stream-stories-homepage"
import HomeVideo from "./home-bg-video.mp4"
import HeroHomeLead from "../components/heros/hero-home-lead"
import CareerFit from "../../static/assets/images/Find-the-Right-Career-Fit.png"
import CareerPlanning from "../../static/assets/images/home-page-career-planning.jpg"
import Humanities from "../../static/assets/images/home-page-career-humanities.png"
import Law from "../../static/assets/images/home-page-career-law.png"
import Arts from "../../static/assets/images/home-page-career-arts.png"
import Trades from "../../static/assets/images/home-page-career-trades.png"
import Science from "../../static/assets/images/home-page-career-science.png"
import Business from "../../static/assets/images/home-page-career-business.png"
import DashboardScreen from "../../static/assets/images/Wingspans-homepage-dashboard.jpg"
import PorfolioScreen from "../../static/assets/images/Wingspans-homepage-portfolio.jpg"
import IndustryPVD from "../../static/assets/images/pvdonuts-logo-trans.png"
import IndustryWingspans from "../../static/assets/images/inventing-heron-icon-trans.png"
import IndustryOomph from "../../static/assets/images/home-page-industry-oomph.png"

import './index.css'

const IndexPage = ({data}) => (
  <section>
    <SEO title="Home" />

    <section className="lead home-lead">
      <div className="bg-video">
        <video className="bg-video__content" autoPlay muted loop>
          <source src={HomeVideo} type="video/mp4" />
          Your browser is not supported!
        </video>
      </div>
      <HeroHomeLead />
    </section>

    <section className="intro home-intro">
      <div className="inner inner-intro">
        <div className="lead component-lead component-lead-default">
          <h2>Over 700 Real-World Stories About Work</h2>
          <p>Get a glimpse into the working world through the eyes of real people in a variety of jobs. Filled with invaluable advice from people seasoned with experience, ranging from data scientists to cartoonists, our in-depth interviews are an authentic portrait of the modern and ever-changing workplace.</p>
        </div>
      </div>
    </section>

    <StreamStoriesHomePage />

    <section className="profiles">
      <div className="inner inner-mentors">

        <blockquote className="component-blockquote component-blockquote-default">
          <p>“I am always doing that which I cannot do, in order that I may learn how to do it.”</p>
          <cite>&mdash; Pablo Picasso</cite>
        </blockquote>

        <div className="career-profiles">
          <div className="call-to-action component-call-to-action component-call-to-action-default">
            <h2>Rich Career Profiles</h2>
            <p>Explore hundreds of career profiles with multidimensional content to help prepare you for a successful future.</p>
            <Link type="button" className="button component-button component-button-default" to="/coming-soon">View Sample Profile</Link>
          </div>
          <div className="stream stream-careers-homepage">
            <div className="component-card component-card-career">
              <div className="category-image">
                <img src={Science} alt="career" />
              </div>
              <div className="category-container">
                <div className="eyebrow component-eyebrow">Science & Technology</div>
                <div className="subcategories">Data Scientist, Machine Learning Engineer, Ecologist, Aerospace Engineer, Materials Scientist</div>
              </div>
            </div>
            <div className="component-card component-card-career">
              <div className="category-image">
                <img src={Humanities} alt="career" />
              </div>
              <div className="category-container">
                <div className="eyebrow component-eyebrow">Humanities</div>
                <div className="subcategories">Anthropologist, Historian, Philosopher, Literary Critic, Political Scientist, Theologist, Visual Artist, Archaeologist</div>
              </div>
            </div>
            <div className="component-card component-card-career">
              <div className="category-image">
                <img src={Law} alt="career" />
              </div>
              <div className="category-container">
                <div className="eyebrow component-eyebrow">Legal</div>
                <div className="subcategories">Judge, Lawyer, Paralegal, Criminal Investigator, Police Officer, Criminologist, FBI Investigator, Forensic Psychiatrist</div>
              </div>
            </div>
            <div className="component-card component-card-career">
              <div className="category-image">
                <img src={Arts} alt="career" />
              </div>
              <div className="category-container">
                <div className="eyebrow component-eyebrow">Arts</div>
                <div className="subcategories">Television Writer, Director, Composer, Radio Reporter, Musician, Talent Agent, Cinematographer, Editor</div>
              </div>
            </div>
            <div className="component-card component-card-career">
              <div className="category-image">
                <img src={Trades} alt="career" />
              </div>
              <div className="category-container">
                <div className="eyebrow component-eyebrow">Trades</div>
                <div className="subcategories">Pipe Fitter, Electrician, Foreman, Drafter, Painter, Carpenter, Builder, Landscaper, Machinist, Line Constructor</div>
              </div>
            </div>
            <div className="component-card component-card-career">
              <div className="category-image">
                <img src={Business} alt="career" />
              </div>
              <div className="category-container">
                <div className="eyebrow component-eyebrow">Business/Finance</div>
                <div className="subcategories">Entrepreneur, Investment Banker, Auditor, Private Equity Professional, Accountant, Actuary, Insurance Agent, Venture Capitalist</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="career-fit">
      <div className="inner inner-career-fit">
        <div className="career-fit-lead">
          <img src={CareerFit} alt="career fit" />
          <div className="career-fit-cta">
            <h2>Find the Right Career Fit</h2>
            <p>We adapt and learn from you with built-in algorithms that recommend careers we think will strike your fancy.</p>
          </div>
        </div>
      </div>
    </section>

    <section className="career-tools">
      <div className="inner inner-career-tools">
        <div className="stream stream-career-tools-homepage">
          <div className="card component-card component-card-tools">
            <img src={PorfolioScreen} alt="portfolio" />
            <h2 className="title">Personalized Portfolios</h2>
            <p className="excerpt">Curate a shareable profile that highlights your unique interests, skills, and abilities.</p>
          </div>
          <div className="card component-card component-card-tools">
            <img src={DashboardScreen} alt="dashboard" />
            <h2 className="title">Motivational</h2>
            <p className="excerpt">Stay motivated and get on your way to a rich and meaningful career! Earn points and unlock rewards.</p>
          </div>
        </div>
      </div>
    </section>

    <section className="career-planning">
      <div className="inner inner-career-planning">
        <blockquote className="component-blockquote component-blockquote-default">
          <p>“If you can see it you can be it”</p>
          <cite>&mdash; former Commissioner of Education, State of Rhode Island</cite>
        </blockquote>
        <div className="career-planning-lead">
          <div className="career-planning-cta">
            <h2>Career Planning Tools</h2>
            <p>Turn your new self-knowledge into an actionable plan for your future. Use our proprietary workflow to develop timelines to achieve the career you always wanted.</p>
          </div>
          <img src={CareerPlanning} alt="curriculum" />
        </div>
      </div>
    </section>

    <section className="industries">
      <div className="inner inner-industries-quote">
        <blockquote className="component-blockquote component-blockquote-default">
          <p>“Ma ka hana ka ‘ike”</p>
          <cite>&mdash; Hawaiian proverb that means “In working one learns”</cite>
        </blockquote>
      </div>
      <div className="inner inner-industries">
        <div className="stream stream-industries-homepage">
          <div className="card component-card component-card-company-partners">
            <img src={IndustryPVD} alt="partner-pvd-donuts" />
            <h3 className="company">PVDonuts</h3>
            <p className="one-word">One word that describes us: <strong>Indulge</strong></p>
          </div>
          <div className="card component-card component-card-company-partners">
            <img src={IndustryWingspans} alt="partner-wingspans" />
            <h3 className="company">Inventing Heron</h3>
            <p className="one-word">One word that describes us: <strong>Human</strong></p>
          </div>
          <div className="card component-card component-card-company-partners">
            <img src={IndustryOomph} alt="partner-oomph" />
            <h3 className="company">oomph</h3>
            <p className="one-word">One word that describes us: <strong>Storytellers</strong></p>
          </div>
        </div>

        <div className="call-to-action component-call-to-action component-call-to-action-default">
          <h2>Industry Partners</h2>
          <p>Help students made connections between careers and industry.</p>
          <Link type="button" className="button component-button component-button-default" to="/coming-soon">Partner With Us</Link>
        </div>
      </div>
    </section>

    <section className="signup" style={{ backgroundColor: '#54d1c1' }}>
      <div className="inner inner-signup">
        <div className="call-to-action component-call-to-action component-call-to-action-signup">
          <blockquote className="component-blockquote component-blockquote-default-inline">
            <p>“Each other’s lives are our best textbooks.”</p>
            <cite>– Gloria Steinem</cite>
          </blockquote>
          <Link type="button" className="button component-button component-button-signup" to="/coming-soon">Sign Up</Link>
        </div>
      </div>
    </section>

  </section>
)

export default IndexPage
