import React from "react"
import { Link } from "gatsby"
import "./hero.css"

const HeroHomeLead = () => (
  <section className="hero-home">
    <div className="inner inner-hero inner-hero-home">
      <div className="call-to-action component-call-to-action component-call-to-action-hero">
        <div className="eyebrow component-eyebrow">Career Resource Centered Around Storytelling</div>
        <h1>Own Your Future!</h1>
        <p>Take a look at the delightfully curious exploits we call work and plan your path through meaningful career exposure, self-reflection, and goal setting.</p>
        <Link type="button" className="button component-button component-button-default" to="/coming-soon">Sign Up</Link>
      </div>
    </div>
  </section>
)
export default HeroHomeLead
