import React from "react"
import CardStoriesHomePage from "../cards/card-stories-homepage"
import CardVideoHomePage from "../cards/card-video-homepage"

const StreamStoriesHomePage = () => (

  <section className="stories stories-homepage">
    <div className="inner inner-stories inner-stories-homepage">
      <div className="stream stream-stories stream-stories-homepage">
        <CardStoriesHomePage />
      </div>
      <div className="stream stream-video stream-stories-video">
        <CardVideoHomePage />
      </div>
    </div>
  </section>
)

export default StreamStoriesHomePage
