import React from "react"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"
import ReactPlayer from 'react-player'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons"

export default function CardVideoHomePage() {

  return (
    <StaticQuery
      query={graphql`
       {
        allCosmicjsStories(limit: 1, filter: {metadata: {story_display_video_on_homepage: {eq: true}}}) {
          edges {
            node {
              id
              title
              slug
              metadata {
                story_job_title
                story_quote
                story_video_url
                story_personal_photo {
                  imgix_url
                }
              }
            }
          }
        }
      }
      `}
      render={data => (
        data.allCosmicjsStories.edges.map(({ node }, idx) => {
          return (
            <div className="component-card component-card-video" key={idx}>
              <div className="component-card-image component-card-image-video">
                <div className="video-wrapper">
                  <ReactPlayer
                    url={node.metadata.story_video_url}
                    light={node.metadata.story_personal_photo.imgix_url}
                    controls={true}
                    playing={true}
                    width='100%'
                    height='100%'
                    playIcon={
                      <>
                        <button className="button component-button component-button-video component-button-video-large">
                          <FontAwesomeIcon className="play-video" icon={faPlayCircle} />
                          Watch Video
                        </button>
                        <button className="button component-button component-button-video component-button-video-small">
                          <FontAwesomeIcon className="play-video" icon={faPlayCircle} />
                          <span className="sr-only">Watch Video</span>
                        </button>
                      </>
                    }
                  />
                </div>
              </div>
              <div className="component-card-meta component-card-meta-video">
                <div className="component-card-meta-header component-card-meta-header-video" style={{display: 'block'}}>
                  <Link className="component-card-title component-card-title-video" to={`/story/${node.slug}`}>{node.metadata.story_job_title}</Link>
                </div>
                <blockquote className="component-blockquote component-blockquote-video" style={{color: '#363636'}}>
                  <p>&ldquo;{node.metadata.story_quote}&rdquo;</p>
                  <cite>&mdash; {node.title}</cite>
                </blockquote>
              </div>
            </div>
          )
        })
      )}
    />
  )
}
